import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CommunicationsForm from 'manage-tritag/components/pages/communications/form'

const LadderPage = () => {
  return (
    <Layout label1="Communications" url="/communications">
      <CommunicationsForm />
    </Layout>
  )
}

export default withAuthenticationRequired(LadderPage)
