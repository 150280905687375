import { api } from '..'

export const communicationsApi = api.injectEndpoints({
  endpoints: build => ({
    sendMessage: build.mutation({
      query: body => ({
        url: 'communications',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['teams'],
    }),
  }),
})

export const { useSendMessageMutation } = communicationsApi
